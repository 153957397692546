<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import BasicInfo from "@/components/loyalty/reward-basic-info" 
import _debounce from 'lodash.debounce';
import Multiselect from "vue-multiselect";

import {
	projectMethods,
  commonMethods
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Reward",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		BasicInfo,
    Multiselect
	},
	data() {
		return {
			title: "",
			items: [],
			isLoading: false,
			fullPage: true,
			reward:{},
			submitted:false,
      products: [],
      selectedProduct:null,
      query: '',
      productImage: null,
      cuponCodeOptions: [
        {id: 'autogenerate_codes', label: 'Autogenerar Cupones'},
        {id: 'single_code', label: 'Agreagr cupón único'},
        {id: 'multiple_codes', label: 'Agregar cupones (Pronto)'},
      ],
      auxCodeOption: {id: 'autogenerate_codes', label: 'Autogenerar Cupones'},
      parameter:{},
      submittedParameter: false
		}
	},
	validations: {
		reward: {
			name: {
				required,
			},
			description: {
				required,
			},
			startDate: {
				required,
			},
		},
    parameter:{
        id: { required },
        value: { required },
      }
	},
	mounted() {
		const rewardId = this.$route.query.id;
		
		if(rewardId){
			this.title = 'Editar Recompensa';
			//load loyalty campaing
      if(rewardId == 1){
        this.reward = {
          _id: 1,
          name: 'HUGO BOSS ALIVE',
          type: { id:"material", label: "Material"},
          status: 1,
          points: 1500,
          startDate: new Date(),
          endDate: null, 
          limit: null
        }
      }else if (rewardId == 2){
        this.reward = {
          _id: 2,
          name: 'Cupon 100 USD',
          type: { id:"coupon", label: "Cupón de descuento fijo"},
          status: 1,
          points: 2400,
          limit: 100,
          stock: 21,
          startDate: new Date(),
          endDate: new Date(), 
          coupon: 100
        }
      }else if (rewardId == 3){

        this.reward = {
          _id: 3,
          name: '2X puntos en cupón',
          type: { id:"points_coupon", label: "Convertir puntos en cupón"},
          status: 1,
          points: 5000,
          limit: null,
          startDate: new Date(),
          coupon: 100,
          coupon_rate: 2
        }
      }
			
		}
		else{
      this.title = 'Nueva Recompensa'
			//new campaing
		}
	},
	created() {
		this.debounceProducts = _debounce(this.searchProduct.bind(this), 1000);
	},
  computed : {
    isParameterIdValid(){
      return this.validateParameterId(this.parameter.id)
    },
  },
	methods: {
    ...projectMethods, 
    ...commonMethods,

    nameWithSKU(p) {
			return `${p.data.sku} — [${p.data.name}]`
		},

    onSelectProductsClicked(){
			
      this.selectedProduct = null;
		},

    searchProduct(query){
			
			this.isLoading = true;

			if(query){
				this.products=[];
				const params ={
					q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active" : 1, "$or": [{"data.name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}*." , "$options": "i"}} ]}`
				}

				this.getItems(params).then((products)=>{
					if(products&& products.data){
						this.products= products.data;
					}
					this.isLoading = false;
				})
			}else{
				this.products = [];
				this.isLoading = false;
				this.selectedProduct=null;
			}
    },
		onCancelRewardClicked(){
			this.$router.push({
            path: `/loyalty-rewards`
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
		},
		onConfirmCreateOrEditRewardClicked(){

		},
    updateProductImage() {
			var file = this.$refs.productImagePicture.files[0];
			this.$refs.productImagePicture.files = null;

			if(file){
				this.uploadMedia(file).then((res) => {
					this.productImage = res.data.url;

					return true;                  
				})
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.log(err);
					this.$notify({ type: 'error', text: this.$t("campaigns.updateCampaignPicture_error"),title:  this.$t("campaigns.new_campaign") });
				})
			}
		},
    removeProductImage(){
			this.productImage = null;
    },
    onAddParameterClicked(){
      this.submittedParameter = true;

      if (this.$v.parameter.$invalid) {
        return;
      }

      if(!this.reward.material)
        this.reward.material = {};

      if(!this.reward.material.customAttributes)
        this.reward.material.customAttributes = [this.parameter]
      else
        this.reward.material.customAttributes.push(this.parameter);
      
        this.submittedParameter = false;
        this.parameter = {}
    },
    onRemoveParameter(par){
      this.reward.material.customAttributes = this.reward.material.customAttributes.filter(p=>p.id!=par.id)
    },
    validateParameterId(id){
      
      let isOk = true;
      isOk = !/^_/.test(id);
      isOk = !/[A-Z]/.test(id) && isOk;
      isOk = !/[!@#$%^&*(),.'?":{}|<>]/.test(id) && isOk
      isOk = !/\s/.test(id) && isOk;
      
      return isOk
    
    }
	},
	
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="checkout-tabs">
			<b-tabs
				pills
				vertical
				nav-class="p-0"
				nav-wrapper-class="col-xl-2 col-sm-3">
				<b-tab active>
					<template v-slot:title>
						<i class="bx bxs-briefcase-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.new_campaign_basicInfo')}}</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">{{$t('campaigns.new_campaign_basicInfo')}}</h4>
								<p class="card-title-desc">{{$t('campaigns.new_campaign_basicInfo_subtitle')}}</p>
								<BasicInfo :reward="reward"></BasicInfo>
							</div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab v-if="reward?.type?.id=='material'">
					<template v-slot:title>
						<i class="bx bx-gift d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">Material</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">Material</h4>
								<p class="card-title-desc mb-0">Seleccioná el producto a brindar como recompensa.</p>
								<div class="row mb-3">
                  <div class="col-sm-6 text-sm-end">
                    <b-spinner
                      v-if="isLoading"
                      class="m-2"
                      variant="primary"
                      role="status">
                    </b-spinner>
                  </div>
                  <div class="col-sm-6">
                    <div class="text-sm-end">
                      <multiselect 
                        v-model="selectedProduct" 
                        id="products" 
                        label="name" 
                        track-by="_id" 
                        :selectLabel="$t('common.select')"
                        :deselectLabel="$t('common.deselect')"
                        :placeholder="$t('campaigns.new_campaign_products_select')"
                        open-direction="bottom" 
                        :options="products" 
                        :multiple="false" 
                        :searchable="true" 
                        :internal-search="false" 
                        :clear-on-select="false" 
                        :close-on-select="true" 
                        :options-limit="300" 
                        :limit="1" 
                        :max-height="600" 
                        :show-no-results="false" 
                        :hide-selected="false"
                        @search-change="debounceProducts"
                        @input="onSelectProductsClicked"
                        :custom-label="nameWithSKU">
                        <template v-slot:noOptions>
                          {{$t('campaigns.new_campaign_products_empty')}}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-6">
                    <label>Nombre*</label>
                      <input
                        type="text"
                        class="form-control"
                      />
                  </div>
                  <div class="col-sm-6">
                    <label>Descripción*</label>
                      <input
                        type="text"
                        class="form-control"
                      />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                    <label>Imagen</label>
                      <input
                      ref="productImagePicture"
                      @change="updateProductImage"
                      accept="image/*"
                      class="form-control "
                      type="file"/>
                    </div>
                  </div>
                  <div class="col-sm-6 flex" v-if="productImage">
                    <div class="avatar-xs me-3 edit-picture-button" v-on:click="removeProductImage">
                    <span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
                      <i class="bx bxs-trash"></i>
                    </span>
                    </div>
                    <img
                    class="rounded me-2"
                    height="120"
                    :src="productImage"
                    data-holder-rendered="true"
                    />
                  </div>
                </div>
                <div class="row">
                  <label for="title">Atributos</label>
                    <div class="row align-items-baseline">
                      <div class="col-5 pe-0">
                        <b-form-input v-model="parameter.id" :placeholder="$t('interactions.key_placeholder')" :class="{ 'is-invalid': submittedParameter && $v.parameter.id.$invalid }" />
                        <div v-if="submittedParameter && !$v.parameter.id.required" class="invalid-feedback">
                          {{$t('interactions.key_required')}}
                        </div>
                        <div v-else-if="submittedParameter && !isParameterIdValid" class="invalid-feedback" style="display: block;">
                          {{$t('interactions.key_error')}}
                        </div>
                      </div>
                      <div class="col-5 pe-0"> 
                        <b-form-input v-model="parameter.value" placeholder="Ingresa el valor" :class="{ 'is-invalid': submittedParameter && $v.parameter.value.$invalid }" />
                        <div v-if="submittedParameter && !$v.parameter.value.required" class="invalid-feedback">
                          {{$t('interactions.type_required')}}
                        </div>
                      </div>
                      <div class="col-2">
                        <button class="btn btn-sm btn-primary" @click="onAddParameterClicked">{{$t('common.add')}}</button>
                      </div>
                    </div>
                    <div class="row mt-3" v-if="reward.material?.customAttributes?.length > 0">
                      <div class="col">
                        <table class="table table-sm align-middle table-nowrap table-hover">
                          <thead class="table-light">
                            <tr>
                              <th scope="col" class="align-middle">Clave</th>
                              <th scope="col" class="align-middle">Valor</th>
                              <th class="text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="par in reward.material?.customAttributes" :key="par.id">
                              <td class="align-middle">{{par.id}}</td>
                              <td class="align-middle">{{par.value}}</td>
                              <td class="text-end"><i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveParameter(par)"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
						</div>
					</b-card-text>
				</b-tab>
        <b-tab v-if="reward?.type?.id=='coupon' || reward?.type?.id=='points_coupon'">
					<template v-slot:title>
						<i class="bx bx-gift d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">Cupones</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">Cupones</h4>
								<p class="card-title-desc">Configurá las opciones para cupones.</p>
                <div class="row" v-if="reward?.type?.id=='coupon'">
                  <div class="col">
                    <div class="mb-3">
                      <label>Valor del cupón*</label>
                      <input
                        v-model="reward.coupon"
                        type="number"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="reward?.type?.id=='points_coupon'">
                  <div class="col">
                    <div class="mb-3">
                      <label>Tasa para convertir puntos a cupón*</label>
                      <input
                        v-model="reward.coupon_rate"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label>Codigos de cupón*</label>
                      <multiselect
                        :options="cuponCodeOptions" 
                        v-model="auxCodeOption"
                        selectLabel=""
                        deselectLabel=""
                        track-by="id" 
                        label="label"
                        :multiple="false"
                        :allowEmpty="false"
                        />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="auxCodeOption.id=='autogenerate_codes'">
                  <div class="col">
                    <div class="mb-3">
                      <label>Largo de código</label>
                      <input
                        v-model="reward.coupon_length"
                        type="number"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="auxCodeOption.id=='autogenerate_codes'">
                  <div class="col">
                    <div class="mb-3">
                      <label>Prefijo</label>
                      <input
                        v-model="reward.prefix_code"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="auxCodeOption.id=='single_code'">
                  <div class="col">
                    <div class="mb-3">
                      <label>Código</label>
                      <input
                        v-model="reward.coupon_code"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab>
					<template v-slot:title>
						<i class="bx bxs-wrench d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.edit_campaign_settings_title')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('campaigns.edit_campaign_settings_title')}}</h4>
							<p class="card-title-desc">Configurá los limites para esta recompensa</p>
							<div class="row" v-if="reward._id">
                <div class="col">
                  <div class="mb-3">
                    <label>Límite</label>
                    <input
                      v-model="reward.limit"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="reward._id">
                <div class="col">
                  <div class="mb-3">
                    <label>Límite por cliente</label>
                    <input
                      v-model="reward.limitByMember"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="reward._id">
                <div class="col">
                  <div class="mb-3">
                    <label>Límite por nivel</label>
                    <input
                      v-model="reward.limitByMembership"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
		<div class="row mt-4">
			<div class="col-sm-6">
			</div>
			<!-- end col -->
			<div class="col-sm-6">
				<div class="text-end">
					<b-button variant="light" class="w-md mb-3" @click="onCancelRewardClicked">{{$t('common.cancel')}}</b-button>
					<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmCreateOrEditRewardClicked">{{$t('common.accept')}}</b-button>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<style scoped>

.edit-picture-button {
	height: 1.5rem !important;
	width: 1.5rem !important;
	top: -5px;
	position: absolute;
	color:#adb5bd
}

.icon-color-grey {
	color:#adb5bd
}

</style>